export class ProvisionDriverParams {
  constructor(public driverName: string, public email: string, public driverId: string, public userType: string) {}
}

export interface AIRecognizedEventParams {
  startDate: string;
  endDate: string;
  driverId: string;
  fleetId: string;
}

export interface ManualDriverParams {
  search?: string;
  limit: number;
  offset: number;
  searchBy?: string;
}

export interface ExternalDriverParams {
  fleetId: string;
  startDate: string;
  endDate: string;
  limit?: number;
  offset?: number;
}

export interface EventStreakParams {
  startDate: string;
  endDate: string;
  driverId: string;
  fleetId: string;
}

export class GetPositiveActionsParams {
  public startDate: string;
  public endDate: string;
  public driverId: string;
  public fleetId: string;
  public includeOnlyPositiveAction: boolean;
  public limit?: number;
  public offset?: number;

  constructor({
    startDate,
    endDate,
    driverId,
    fleetId,
    limit,
    offset,
    includeOnlyPositiveAction,
  }: {
    startDate: string;
    endDate: string;
    driverId: string;
    fleetId: string;
    limit: number;
    offset: number;
    includeOnlyPositiveAction: boolean;
  }) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.driverId = driverId;
    this.fleetId = fleetId;
    this.limit = limit;
    this.offset = offset;
    this.includeOnlyPositiveAction = includeOnlyPositiveAction;
  }
}

export interface GetPositiveActionsResponse {
  limit: number;
  skip: number;
  rows: any[];
  totalCount: number;
}

export interface AIEventStats {
  eventType: string;
  averageEvents?: number;
  totalEvents: number;
}

export interface AIRecognizedEventsResponse {
  fleetId: string;
  driverId: string;
  stats: AIEventStats[];
}

export interface StreakInfo {
  [key: string]: Streak;
}

export interface Streak {
  longestStreak: number;
  runningStreak: number;
  isRunningStreakLongest: boolean;
  longestStreakStartTimestamp?: string;
  longestStreakEndTimestamp?: string;
}

export interface EventStreakResponse {
  fleetId: string;
  driverId: string;
  streaks: StreakInfo;
  lastTripId?: string;
  lastTripStartTime?: string;
  updatedAt?: string;
}

export interface PaginationState {
  pageIndex: number;
}

export interface DriverDetails {
  accountStatus?: string;
  driverId?: string;
  driverName?: string;
  email?: string;
  userType?: string;
  username?: string;
  loader?: boolean;
  personId?: string;
  eventsPer100Units?: number;
  score?: number;
  tripCount?: number;
  tripDistance?: number;
  tripDuration?: number;
  driverType?: string;
  eventCount?: DriverDetailsEventCount;
  lastCoachedBy?: string;
  lastCoachedEvents?: string[];
  lastCoachedEventsCount?: number;
  lastCoachedOn?: string;
  requireCoaching?: boolean;
  deleteDriverLoader?: boolean;
  violations?: any[];
}

export interface DriverDetailsEventCount {
  [key: string]: number;
}

export interface CoachingSessionEventDiff {
  [key: string]: {
    current: number;
    diff: number;
    percent: number;
  };
}

export interface DriversDetailsResponse {
  message: string;
  registeredDriver: RegisteredDriver;
}

export interface RegisteredDriver {
  sub: string;
  email: string;
  driverId: string;
  createdAt: Date;
  appMetadata: AppMetadata;
  userMetadata: UserMetadata;
  deleteDriverLoader?: boolean;
  id?: number;
  updatedAt?: Date;
  driverName?: string;
}

export interface AppMetadata {
  fleetId?: string;
  driverId?: string;
  customerName?: string;
  personId?: string;
  userType: string;
}

export interface UserMetadata {
  personid?: string;
  timezone?: string;
  dateFormat?: string;
  metricUnit?: string;
  driverSeatSide?: string;
}

interface DriverRow {
  sub?: string;
  email?: string;
  status: string | null;
  fleetId?: string;
  driverId: string;
  createdAt?: string;
  updatedAt?: string;
  driverName: string;
  appMetadata?: AppMetadata;
  userMetadata?: UserMetadata;
}

export interface RegisteredDriverResponse {
  rows: DriverRow[];
  skip: number;
  limit: number;
  totalCount: number;
}

interface EventCount {
  'Traffic-Speed-Violated': number;
  Cornering: number;
  'Traffic-STOP-Sign-Violated': number;
  'Harsh-Braking': number;
  'Harsh-Acceleration': number;
  'Tail-Gating-Detected': number;
  'Lane-Drift-Found': number;
  'Distracted-Driving': number;
  'Drowsy-Driving-Detected': number;
  'Forward-Collision-Warning': number;
  'Unbuckled-Seat-Belt': number;
  'Roll-Over-Detected': number;
  'Traffic-Light-Violated': number;
  total: number;
}

interface ManualDriver {
  score?: number;
  driverId: string;
  tripCount?: number;
  driverName: string;
  eventCount?: EventCount;
  tripDistance?: number;
  tripDuration?: number;
}

interface Data {
  drivers: ManualDriver[];
}

export interface ManualDriversResponse {
  data: Data;
  code: number;
  skip: number;
  limit: number;
  totalCount: number;
}

export enum UnitMetrics {
  miles = 'Miles',
  kilometers = 'Kilometers',
}

export interface DriverStatsResponse {
  aggregate: Aggregate;
  rows: Row[];
  details: Details;
}

export interface Aggregate {
  distance: number;
  tripDuration: number;
  tripCount: number;
  eventsPer100Units: number;
  eventCount: { [key: string]: number };
  driverName: string;
  eventDiff: { [key: string]: EventDiff };
  totalEventsDiff: number;
}

export interface EventDiff {
  current: number;
  diff: number;
  percent: number;
}

export interface Details {
  from: Date;
  to: Date;
  compareFrom: Date;
  compareTo: Date;
  limit: number;
  skip: number;
  unit: string;
}

export interface Row {
  key: null;
  value: Value;
}

export interface Value {
  score: number;
  tripCount: number;
  eventCount: { [key: string]: number };
  tripDistance: number;
  tripDuration: number;
}
